import React from "react";
import ptolLogo from "../resources/plytol_logo.png";
import { AiOutlineInstagram, AiFillLinkedin } from "react-icons/ai";
// import { FaXTwitter } from "react-icons/fa6";
import "../styles/header.css";
import { useNavigate } from "react-router-dom";

export default function Header({ siteState }) {
    const navigate = useNavigate();

    return (
        <header>
            <div className="left">
                <img
                    src={ptolLogo}
                    alt="Plytol Logo Dark"
                />
                <div className="header--links">
                    <button
                        onClick={() => navigate("/")}
                        className={siteState === 0 ? "header--tab--active" : "header--tab"}>
                        Home
                    </button>
                    <button
                        onClick={() => navigate("/about")}
                        className={siteState === 1 ? "header--tab--active" : "header--tab"}>
                        About
                    </button>
                    <button
                        onClick={() => navigate("/contact")}
                        className={siteState === 2 ? "header--tab--active" : "header--tab"}>
                        Contact Us
                    </button>
                </div>
            </div>
            <div className="header--icons">
                {/* <a href="/"
                    target="_blank" rel="noreferrer">
                    <FaXTwitter />
                </a> */}
                <a
                    href="https://www.linkedin.com/company/plytol-ltd/"
                    target="_blank" rel="noreferrer">
                    <AiFillLinkedin />
                </a>
                <a
                    href="https://www.instagram.com/plytol/"
                    target="_blank" rel="noreferrer">
                    <AiOutlineInstagram />
                </a>
            </div>
        </header>
    )
}
