import React from "react";
import "../styles/contact.css";
import Header from "./Header";
import Footer from "./Footer";

export default function ContactPage() {
    return (
        <div>
            <Header siteState={2} />
            <div className="contact-back">
                <div className="contact">
                    <h1>Contact Us</h1>
                    <p>We're committed to supporting you.</p>
                    <p>Contact us today for enquiries, service provider support, or general queries!</p>

                    <p><span className="bold">Email:</span> <a href="mailto: contact@plytol.co.uk">contact@plytol.co.uk</a></p>
                </div>
            </div>
            <Footer />
        </div>
    )
}
