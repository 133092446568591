import React from "react";
import Coel from "../resources/about_coel.png";
import Morgan from "../resources/about_morgan.png";
import Dusa from "../resources/about_dusa.png";
import "../styles/about.css";
import Header from "./Header";
import Footer from "./Footer";

export default function About() {
    return (
        <div>
            <Header siteState={1} />
            <div className="about">
                <h1>About Plytol Ltd</h1>

                <p>Plytol Ltd is a dedicated software development company specialising in supporting local businesses with bespoke software development and digital innovation.</p>
                <p>Our mission: To empower local enterprises with cutting edge technology solutions tailored to their unique needs.</p>

                <h2>Origins</h2>

                <div className="timeline">
                    <div className="timeline-item">
                        <div className="left">
                            <h3>The Spark</h3>
                            <p>Summer 2023</p>
                        </div>

                        <p>Plytol started out in 2023 as an idea created by three friends to develop fun applications for public events. Our first web-app, CheltPubGolf, was created as a fun way to streamline the popular drinking game, Pub Golf.</p>
                    </div>

                    <div className="vl">
                    </div>

                    <div className="timeline-item">
                        <div className="left">
                            <h3>By Popular Demand</h3>
                            <p>Autumn 2023</p>
                        </div>

                        <p>After releasing CheltPubGolf to the public, we generated some interest among local businesses. As a way to respond to this interest, we started going by the name Plytol, after a roadtrip to <span className="bold">Ply</span>mouth and Bris<span className="bold">tol</span> in 2022.</p>
                    </div>

                    <div className="vl">
                    </div>

                    <div className="timeline-item">
                        <div className="left">
                            <h3>Welcome to Plytol</h3>
                            <p>Winter 2023</p>
                        </div>

                        <p>Plytol Ltd is officially founded through Company House as a limited company.</p>
                    </div>

                    <div className="vl">
                    </div>

                    <div className="timeline-item">
                        <div className="left">
                            <h3>The First of Many</h3>
                            <p>Winter 2023</p>
                        </div>

                        <p>Plytol begins developing its first mobile App, AirDeska, led by an external client.</p>
                    </div>

                    <div className="vl">
                    </div>

                    <div className="timeline-item">
                        <div className="left">
                            <h3>Release of AirDeska</h3>
                            <p>Summer 2024</p>
                        </div>

                        <p>Plytol releases AirDeska on the Apple App Store and the Google Play Store, marking its first mobile app release.</p>
                    </div>

                    <div className="vl">
                    </div>

                    <div className="what-next">
                        <h3>What's next?</h3>
                        <p>With more projects lined up, we are ready for the next collaboration!</p>

                        <button>Contact Us</button>
                    </div>
                </div>

                <h2>Meet the Team</h2>

                <div className="meet-team">

                    <div className="item">
                        <img src={Coel} alt="Coel Richmond Headshot" />
                        <h3>Coel Richmond</h3>
                        <p>Graphic Designer</p>
                        <p>Project Development Manager</p>
                    </div>

                    <div className="item">
                        <img src={Morgan} alt="Morgan Clifford Headshot" />
                        <h3>Morgan Clifford</h3>
                        <p>Full-stack Software Developer</p>
                        <p>iOS Specialist</p>
                    </div>

                    <div className="item">
                        <img src={Dusa} alt="Medusa Dempsey Headshot" />
                        <h3>Medusa Dempsey</h3>
                        <p>Full-stack Software Developer</p>
                        <p>Android Specialist</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
