import React from "react";
import WebDesign from "../resources/main_web.png";
import AppDev from "../resources/main_app.png";
import SoftCons from "../resources/main_cons.png";
import ADLogo from "../resources/ad_logo.png";
import BAFLogo from "../resources/baf_logo.png";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

export default function Homepage() {
  const navigate = useNavigate();

  return (
    <div>
      <Header siteState={0} />
      <main>
        <div className="main-hero">
          <div className="main-hero-left">
            <h1>Turning your Ideas</h1>
            <h1 className="main-bold">Into Reality</h1>
            <p>Supporting local businesses with Software Development tailored to you</p>
          </div>
          <div className="main-hero-right">
            <button onClick={() => navigate("/contact")}>Contact Us</button>
            <button className="button-dark" onClick={() => navigate("/about")}>Our Services</button>
          </div>
        </div>

        <div className="main-our-vision">
          <h1>Our Vision</h1>
          <div className="main-our-text">
            <p>At Plytol, we envision a future where small businesses thrive through innovative software solutions, exceptional product rendering, and dynamic digital experiences.</p>
            <p>We aim to empower our clients by providing cutting-edge technology, bespoke apps, and visually stunning websites tailored to their unique needs. Through creativity, precision, and a commitment to excellence, we aspite to become the trusted partner for small businesses seeking to transform their ideas into reality. Together, we will build a more connected, efficient, and visually captivating digital landscape for small enterprises everywhere.</p>
          </div>
        </div>

        <div className="main-what">
          <h1>What We Offer</h1>
          <p>Why Choose Us?</p>

          <br />

          <div className="box-left">
            <div className="content">
              <h1>Website Design</h1>
              <div className="text">
                <p>We offer a comprehensive and tailored approach to creating visually stunning and highly functional websites. Specialising in user-centric designs, we ensure each website is not only aesthetically pleasing, but also intuitive and easy to navigate.</p>
                <p>Our design team works closely with clients to understand their brand identity, target audience, and specific needs, crafting custom websites that reflect the unique personality and objectives of each business. With a focus on responsive design, we guarantee that all websites perform seamlessly across various devices and platforms.</p>
              </div>
            </div>
            <div className="image">
              <img src={WebDesign} alt="Web Design" />
            </div>
          </div>


          <br />

          <div className="box-right">
            <div className="image">
              <img src={AppDev} alt="App Development" />
            </div>
            <div className="content">
              <h1 id="app-dev">App Development</h1>
              <div className="text">
                <p>Designed to bring innovative, scalable, and user-centric mobile applications to life, our service encompasses the entire development lifecycle, from initial concept to design to coding, testing, and deployment. We specialise in both iOS and Android platforms, ensuring cross-platform compatibility and optimal performance.</p>
                <p>So whether its a startup idea or an enterprise solution, our commitment to quality and continued client satisfaction is our top priority.</p>
              </div>
            </div>
          </div>

          <br />

          <div className="box-left">
            <div className="content">
              <h1 id="soft-cons">Software Consultancy</h1>
              <div className="text">
                <p>We collaborate closely with clients to understand their unique challenges and objectives.</p>
                <p>From initial consultation to post-deployment support, our team ensures a seamless experience, fostering digital transformation and driving business growth.</p>
                <p>With commitment to customer satisfaction, we can be a trusted partner for small businesses seeking to enhance their technological capabilities and their customers' digital experience.</p>
              </div>
            </div>

            <div className="image">
              <img src={SoftCons} alt="Software Consultancy" />
            </div>
          </div>
        </div>

        <div className="main-partners">
          <h1>Our Partners</h1>
          <div className="images">
            <a href="https://airdeska.co.uk"><img src={ADLogo} alt="AirDeska Logo" /></a>
            <img src={BAFLogo} alt="Ben Aldworth Film Logo" />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}
