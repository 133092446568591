import React from "react";
import plytolLight from "../resources/plytol_logo_light.png";
import footerPhones from "../resources/footer_phones.png";
import { MdEmail } from "react-icons/md";
import "../styles/footer.css";

export default function Footer() {
    return (
        <footer>
            <div className="footer--top">
                <div className="footer--left">
                    <img src={plytolLight} alt="Plytol Logo White" className="footer--image" />
                    <div className="footer--email">
                        <MdEmail />
                        <p><a href="mailto: contact@plytol.co.uk">contact@plytol.co.uk</a></p>
                    </div>
                </div>

                <div className="footer--right">
                    <img src={footerPhones} alt="Phones with Plytol Logo" />
                </div>

            </div>
            <p>Plytol Ltd is a company registered England and Wales (company No. 15154920)
                Lytchett House, 13 Freeland Park, Wareham Road, Poole, Dorset, BH16 6FA</p>
        </footer>
    )
}
